export default onBeforeRoute;

import { getCurrentLang, setCurrentLang } from "@/lib/translations";
import { PageContext } from "vike/types";

// called also for each prerendered page
function onBeforeRoute(pageContext: PageContext) {
  const url = pageContext.urlParsed;

  let path = url.pathname;

  // we want to update lang only before prerender, on the server
  // this method is called in browser as well
  if (typeof document === "undefined") {
    if (pageContext.locale) {
      setCurrentLang(pageContext.locale);
      console.log(`url: ${path} context lang ${getCurrentLang()} locale [${pageContext.locale}]`);
    } else {
      console.log(`url: ${path} locale not available in the request!`);
    }
  }

  // we must call it for some reason
  const lang = getCurrentLang();
  // console.log(`#HeroTitle lang = ${getCurrentLang()} value ${t("HOME.HERO.TITLE")}`);

  if (lang !== "pl") {
    console.log(path);
    path = path.replace(`/${lang}`, "");
    if (!path.startsWith("/")) {
      path = "/" + path;
    }

    return {
      pageContext: {
        urlLogical: path,
      },
    };
  }

  return undefined;
}
